import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Cta from "../components/cta"
import Kucharze from "../components/about-picture"
import Button from "../components/button"
const About = () => (
  <Layout>
    <SEO title="O nas | poznaj naszych kucharzy" description="pojedzone, szef kuchni, pyszne jedzenie, dąbrówka, ulanów"/>
    <Header title="O NAS" />
    <section className="about-us text-center ">
      <div className="container">
        <div className="row inset">
          <p className="about-paragraph">
            Urzeczywistnienie pasji, odkrywanie nowych smaków oraz chęć
            zaspokajania potrzeb kulinarnych pozwoliły nam stworzyć to
            szczególne miejsce. <b>„Pojedzone”</b> to lokal gastronomiczny w miejsowości Dąbrówka
            zbudowany na wieloletnim doświadczeniu naszych kucharzy, szeregu
            umiejętności, kreatywności, wiedzy oraz pasji. Powstał również po
            to, żeby przyjemnie spędzać czas z rodziną i przyjaciółmi.
          </p>
          <p className="about-paragraph mt-3">
            {" "}
            <b>Naszym celem</b> jest stworzenie miejsca, które zaspokoi
            oczekiwania klientów i wprowadzi ich w prawdziwą ucztę kulinarną.
          </p>
        </div>
        <h3 className="mb-5 about-chef font-weight-bold ">
          POZNAJ NASZYCH KUCHARZY
        </h3>
        <div className="row mt-5 d-flex justify-content-center align-items-start">
          <div className="col-12 col-md-6 text-left">
            <p>
              Oddajemy w ręce klientów bogate menu, opracowane na najnowszych
              trendach smakowych, jak i tradycyjnych potrawach. Siła tkwi w{" "}
              <b>różnorodności</b> , wobec tego karta dań będzie ukierunkowana
              na sezonowość. Kuchnia <b>„Pojedzone”</b> to gastronomiczna kraina
              wielu kultur: polskiej, amerykańskiej włoskiej, wietnamskiej i
              wielu innych. Jesteśmy otwarci na nowe smaki i doznania kulinarne.
            </p>
            <div className="about-button pt-5">
            <Link to="/kontakt/">
              <Button title="KONTAKT" backtitle="POJEDZONE" style="back m-7" />
            </Link>
            </div>
           
          </div>
          <div className="col-12 col-md-6 mt-5 mt-md-0">
            <Kucharze />
          </div>
        </div>
      </div>
    </section>
    <Cta />
  </Layout>
)

export default About

{
  /* <p>Oddajemy w ręce klientów bogate menu, opracowane na najnowszych trendach smakowych, jak i
tradycyjnych potrawach. Siła tkwi w różnorodności, wobec tego karta dań będzie ukierunkowana na
sezonowość. Kuchnia „Pojedzone” to gastronomiczna kraina wielu kultur: polskiej, amerykańskiej
włoskiej, wietnamskiej i wielu innych. Jesteśmy otwarci na nowe smaki i doznania kulinarne.</p>
<p>Naszym priorytetem jest JAKOŚĆ. Jako kucharze nie wyobrażamy sobie pracy bez świeżych i
zdrowych produktów. W związku z tym pracujemy tylko na starannie dobranych składnikach, które
pozyskujemy od lokalnych rolników i sadowników.</p>
<p>„Przyjemność brzucha jest podstawą i korzeniem wszelkiego dobra” - Epikur</p> */
}
